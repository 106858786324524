import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'

const NotFound = () => {
    return (
        <Layout>
        <Head title='lost cats'/>
            <h1>PAGE DOES NOT EXIST</h1>
            <p><Link to="/"> HEAD HOME FELLA </Link></p>

        </Layout>

    )
}

export default NotFound